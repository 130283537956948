<template>
    <div id='coming-soon' class='container page'>
        <h2 id="chachi-v2"><mark>Chachi v2</mark></h2>
        <p>We are building a Wiki for Teaching.</p>
        <p>With Chachi, classrooms become more engaging and students become lifelong learners.</p>
        <h3 id="what-does-that-mean-">What does that mean?</h3>
        <p>Chachi is going to be a shared, open repository of activity suggestions that cover the needs of 21st century learners.</p>
        <p>Search for a <strong>life skill</strong> or a chapter in the <strong>textbook</strong> and you&#39;ll be presented with dozens of engaging activities that you can try in your classroom. Every single suggestion in Chachi will be linked with textbooks and life skills, along with material for learning more about the topic.</p>
        <p>We will cover a variety of life skills, such as - </p>
        <ul>
        <li>Social-emotional learning (relationships, mindfulness, empathy)</li>
        <li>Effective communication</li>
        <li>Logic and reasoning (cognitive biases, critical analysis)</li>
        <li>Gender sensitization (hidden patriarchy, sexual health)</li>
        <li>Personal finance (budgeting, taxes, investment)</li>
        <li>Media and tech literacy (fake news, social media)</li>
        </ul>
        <p>... and many more.</p>
        <p>Our content will be available in multiple languages, beginning with Hindi, Punjabi, Marathi, Odia, Gujarati and English.</p>
        <h3 id="why-chachi-">Why Chachi?</h3>
        <p>Most of what students need to succeed in their lives is not covered in the textbooks. Unfortunately, in most classrooms today, the everyday conversation is limited to just the textbooks and exams.</p>
        <p>Furthermore,</p>
        <ol>
        <li>Data tells us that most teachers find it difficult to make their lectures interesting for students. </li>
        <li>Research tells us that students learn better if the teacher engages them in an interesting conversation.</li>
        <li>Reality tells us that teachers can try new activities in class more easily if these are related to the textbook.</li>
        </ol>
        <p>The only skill that is going to prepare our learners for the 21st century is the ability to be a lifelong learner. For that, they need to engage in metacognitive activities that are interesting and just the right amount of challenging. </p>
        <p>With Chachi, teachers should be able to seamlessly weave in the necessary life skills in their everyday classroom conversation. This means no compromise from &quot;the exam point of view&quot; <strong>and</strong> preparing students for the future. </p>
        <p>And all of this will happen in an experiential learning manner!</p>
        <h3 id="who-writes-the-content-">Who writes the content?</h3>
        <p>The content on Chachi is sourced from experts in the education sector. These include teachers, administrators, policymakers, workshop facilitators and researchers.</p>
        <h3 id="how-can-i-contribute-">How can I contribute?</h3>
        <p>Thank you so much for being interested in our work! You can head over to <a href="#/contact">the contact page</a> and get in touch with us.</p>
        <p>We are currently seeking help from –</p>
        <ol>
        <li>Domain experts in education: To help us develop more content and test the app in diverse educational settings</li>
        <li>Designers: To help us create an intuitive UI/UX</li>
        <li>Marketeers: To help us build an audience</li>
        </ol>
        <h3 id="when-will-v2-be-available-">When will v2 be available?</h3>
        <p>We are hoping for a release in the second half of March, 2021! You can continue to use Chachi v1 until then.</p>


    </div>

</template>


<script>
export default {
	name: 'Coming Soon',
}
</script>

<style scoped>
</style>